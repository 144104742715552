import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Login, RecoverPassword, Register, ChangePassword, Profile, 
        Store, Webhooks, NewArticle, ActiveArticles,
        Facturas, NuevaFacrura, OrdenVenta, NewOrdenVenta, NotFound,
        Items,  ExtraUpdate, OrdenCompra, NewOrdenCompra
        } from './page';
import { Footer, Navbar, CustomSidebar, FacturacionDetallada, CompraDetallada, VentaDetallada, ArticuloDetallada } from './components';
import './App.css';
import { DataContext } from './contex/DataContext';
import ProtectedRoute from './utils/ProtectedRoute';

function App() {
  const { ambiente } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();

  // Definir las rutas donde no quieres mostrar el sidebar
  const hideSidebarRoutes = ['/', '/recoverPassword', '/register', '/changePassword'];
  
  // Verificar si la ruta actual está en la lista de rutas donde no mostrar el sidebar
  const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname) || location.key === 'default' || location.pathname === '*';

  const error404 = location.key === 'default' || location.pathname === '*';

  const containerStyle = (path) => {
    const hiddenOverflowPaths = ['/new-article', '/nueva-factura', '/new-orden-venta','/new-orden-compra'];

    if (path.startsWith('/facturas/')) {
      return { flex: 1 };
    }
    if (path.startsWith('/active-articles/')) {
      return { flex: 1 };
    }
    if (path.startsWith('/orden-compra/')) {
      return { flex: 1 };
    }
    if (path.startsWith('/orden-venta/')) {
      return { flex: 1 };
    }
  
    if (hiddenOverflowPaths.includes(path)) {
      return { flex: 1, overflow: 'visible', position: 'relative'   };
    }
  
    return { flex: 1, overflow: 'hidden' };
  };

  useEffect(() => {
    const organizationID = localStorage.getItem('organizationID');
    if (['/', '/recoverPassword', '/register', '/changePassword'].includes(location.pathname) && organizationID) {
      navigate('/store');
    }
  }, [location.pathname, navigate]);

  return (
    <div className='app-conatiner'>
      {!error404 && <Navbar />}
      
      <div style={{ display: 'flex', marginTop: '20px'}}>
        {!shouldHideSidebar && <CustomSidebar ambiente={ambiente} />}
        <div style={containerStyle(location.pathname)}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/recoverPassword" element={<RecoverPassword />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/changePassword" element={<ChangePassword />} />

            <Route
              exact
              path="/store"
              element={
                <ProtectedRoute>
                  <Store />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/webhooks"
              element={
                <ProtectedRoute>
                  <Webhooks />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/extraUpdate"
              element={
                <ProtectedRoute>
                  <ExtraUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/items"
              element={
                <ProtectedRoute>
                  <Items />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/new-article"
              element={
                <ProtectedRoute>
                  <NewArticle />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/active-articles"
              element={
                <ProtectedRoute>
                  <ActiveArticles />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/active-articles/:id"
              element={
                <ProtectedRoute>
                  <ArticuloDetallada />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/facturas"
              element={
                <ProtectedRoute>
                  <Facturas />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/facturas/:id" 
              element={
                <ProtectedRoute>
                  <FacturacionDetallada />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/nueva-factura"
              element={
                <ProtectedRoute>
                  <NuevaFacrura />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/orden-venta"
              element={
                <ProtectedRoute>
                  <OrdenVenta />
                </ProtectedRoute>
              }
            />  
            <Route
              exact
              path="/orden-venta/:id" 
              element={
                <ProtectedRoute>
                  <VentaDetallada />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/new-orden-venta"
              element={
                <ProtectedRoute>
                  <NewOrdenVenta />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/orden-compra"
              element={
                <ProtectedRoute>
                  <OrdenCompra />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/orden-compra/:id" 
              element={
                <ProtectedRoute>
                  <CompraDetallada />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/new-orden-compra"
              element={
                <ProtectedRoute>
                  <NewOrdenCompra />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} /> 
          </Routes>
        </div>
      </div>
      <div style={{ maxWidth: '100vw' }}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
