import React, { useEffect, useState } from 'react';
import "../styles/extraUpdate.css"
import axios from 'axios';
import { GoChevronRight, GoChevronDown } from "react-icons/go";
import { storeColors } from '../json/colores';

export const ExtraUpdate = () => {
    const [organizationID, setOrganizationID] = useState(null);
    const [data, setData] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]); 
    const [ordersData, setOrdersData] = useState({}); 

    const getStores = async () => {
        if (organizationID) {
            try { 
                const res = await axios.get(`https://facturacionei3.com/ep_StoreEcart/StoresWithoutWebhooks?OrganizationID=${organizationID}`);
                if (res.status === 200) {
                    setData(res.data);  
                } 
            } catch (error) {
                console.log('Error al realizar la solicitud:', error);
            }
        } 
    };

    const fetchLastFiveOrders = async (storeName) => {
        try {
            const res = await axios.post('https://facturacionei3.com/ep_EcartOrders/lastFive', {
                OrgID: organizationID,
                Store: storeName
            });
            
            if (res.status === 200) {
                setOrdersData(prevState => ({
                    ...prevState,
                    [storeName]: res.data
                }));
            }
        } catch (error) {
            console.log('Error al obtener los últimos 5 pedidos:', error);
        }
    };

    const getImagePath = (storeName) => {
        const formattedName = storeName.toLowerCase().replace(/\s+/g, '');
        try {
            return require(`../img/logoStore/${formattedName}.png`);
        } catch (err) {
            return require(`../img/logoStore/default.png`);
        }
    };

    const toggleRow = (index, storeName) => {
        const currentIndex = expandedRows.indexOf(index);
        const newExpandedRows = [...expandedRows];

        if (currentIndex >= 0) {
            newExpandedRows.splice(currentIndex, 1);
        } else {
            newExpandedRows.push(index);
            if (!ordersData[storeName]) { 
                fetchLastFiveOrders(storeName);
            }
        }

        setExpandedRows(newExpandedRows);
    };

    useEffect(() => {
        const orgID = localStorage.getItem('organizationID');
        setOrganizationID(orgID);
    }, []);

    useEffect(() => {
        getStores();
    }, [organizationID]);

    return (
        <div className="extraUpdate_container">
            <div className='extraUpdate_container_h2'>
                <h2>ExtraUpdate de las tiendas</h2>
            </div>

            <div className='extraUpdate_tabla'>
                <table className="extraUpdate_table">
                    <thead>
                        <tr>
                            <th className="col-1">LOGO</th>
                            <th className="col-3">NOMBRE DE LA TIENDA</th>
                            <th className="col-2">CONFIGURAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.stores.map((store, index) => {
                                const isExpanded = expandedRows.includes(index);
                                const storeColor = storeColors[store.Ecommerce] || '#ffffff'; 
                                const orders = ordersData[store.Ecommerce] || [];

                                return (
                                    <React.Fragment key={index}>
                                        <tr className="extraUpdate_table_title">
                                            <td onClick={() => toggleRow(index, store.Ecommerce)}>
                                                {isExpanded ? <GoChevronDown size={25} /> : <GoChevronRight size={25} />}
                                                <img src={getImagePath(store.Ecommerce)} alt="" />
                                            </td>
                                            <td style={{fontWeight:'800'}}>{store.Ecommerce}</td>
                                            <td>
                                                <button className='habilitado'>Actualizar</button>
                                            </td>
                                        </tr>
                                       
                                        {isExpanded && (
                                            <tr>
                                                <td colSpan="3" style={{border: "1px solid #eaebf2"}}>
                                                <p>ORDENES:</p>
                                                    <table className="expanded-table">
                                                        
                                                        <thead >
                                                            <tr>
                                                                <th>ID STORE</th>
                                                                <th>NÚMERO DE ORDEN DE ORDEN ZOHO</th>
                                                                <th>NOMBRE DE CONTACTO</th>
                                                                <th>TOTAL</th>
                                                                <th>FECHA</th>
                                                            </tr>
                                                        </thead>
                                                        
                                                        <tbody>
                                                            
                                                            {orders.map((order, orderIndex) => (
                                                                <tr key={orderIndex} className="extraUpdate_table_title">
                                                                    <td>{order.IdStore}</td>
                                                                    <td>{order.OrderNumberZoho}</td>
                                                                    <td>{order.ContactName}</td>
                                                                    <td>{order.Total}</td>
                                                                    <td>{new Date(order.Date).toLocaleDateString()}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};
