import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
    AlignLeftOutlined, BarsOutlined, ContainerOutlined, DatabaseOutlined, FileDoneOutlined,
    FileAddOutlined, FileTextOutlined, ShopOutlined, ShoppingOutlined
} from '@ant-design/icons';
import '../styles/customSidebar.css';

const { Sider } = Layout;
const { SubMenu } = Menu;

export const CustomSidebar = ({ ambiente }) => {
    return (
        <Sider className="site-layout-background" style={{ marginTop: '40px' }}>
            <Menu className="container_customSidebar" mode="inline" defaultSelectedKeys={['1']} theme="dark">
                <SubMenu key="marketplaces" icon={<ShopOutlined />} title="Marketplaces">
                    <Menu.Item key="1" icon={<ShoppingOutlined />}>
                        <Link to="/store">Mis Tiendas</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<BarsOutlined />}>
                        <Link to="/items">Artículos</Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<AlignLeftOutlined />}>
                        <Link to="/webhooks">Webhooks</Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<AlignLeftOutlined />}>
                        <Link to="/extraUpdate">Extra Update</Link>
                    </Menu.Item>
                </SubMenu>
                {ambiente === "DEVELOPER" && (
                    <>
                        <SubMenu key="inventory" icon={<DatabaseOutlined />} title="Inventario">
                            <Menu.Item key="5" icon={<FileAddOutlined />}>
                                <Link to="/new-article">Nuevo Artículo</Link>
                            </Menu.Item>
                            <Menu.Item key="6" icon={<FileDoneOutlined />}>
                                <Link to="/active-articles">Artículos</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="facturacion" icon={<FileTextOutlined />} title="Facturación">
                            <Menu.Item key="7" icon={<FileDoneOutlined />}>
                                <Link to="/facturas">Facturas</Link>
                            </Menu.Item>
                            <Menu.Item key="8" icon={<FileAddOutlined />}>
                                <Link to="/nueva-factura">Nueva Factura</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="ordenesVentas" icon={<ContainerOutlined />} title="Ordenes de Ventas">
                            <Menu.Item key="9" icon={<ContainerOutlined />}>
                                <Link to="/orden-venta">Ordenes de Ventas</Link>
                            </Menu.Item>
                            <Menu.Item key="10" icon={<FileAddOutlined />}>
                                <Link to="/new-orden-venta">Nueva Ordenes de Ventas</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="ordenesCompra" icon={<ContainerOutlined />} title="Orden de Compra">
                            <Menu.Item key="11" icon={<ContainerOutlined />}>
                                <Link to="/orden-compra">Orden de Compra</Link>
                            </Menu.Item>
                            <Menu.Item key="12" icon={<FileAddOutlined />}>
                                <Link to="/new-orden-compra">Nueva Ordenes de Compra</Link>
                            </Menu.Item>
                        </SubMenu>
                    </>
                )}
            </Menu>
        </Sider>
    );
};
