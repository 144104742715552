import React, { useEffect, useState } from 'react';
import "../styles/webhooks.css"
import axios from 'axios';

export const Webhooks = () => {
    const [organizationID, setOrganizationID] = useState(null);
    const [data, setData] = useState(null);
    const [enabled, setEnabled] = useState(null);

    const getStores = async () => {
        if (organizationID) {
            try { 
                const res = await axios.get(`https://facturacionei3.com/ep_StoreEcart/StoresWithWebhooks?OrganizationID=${organizationID}`);
                if (res.status === 200) {
                    setData(res.data);  
                } 
            } catch (error) {
                console.log('Error al realizar la solicitud:', error);
                console.log(null);  
            }
        } 
    };

    // Obtener la ruta de la imagen
    const getImagePath = (storeName) => {
        const formattedName = storeName.toLowerCase().replace(/\s+/g, '');
        try {
            return require(`../img/logoStore/${formattedName}.png`);
        } catch (err) {
            return require(`../img/logoStore/default.png`);
        }
    };


    // especifica si las tienda estan habilitadas
    const specificationNotEnabled = async () => {
        
        if (organizationID) {
            try { 
                const res = await axios.get(`https://facturacionei3.com/ep_WebhooksEcart?OrganizationID=${organizationID}&t=${Date.now()}`);
                
                if (res.status === 200) {
                    setEnabled(res.data);  
                } 
            } catch (error) {
                console.log('Error al realizar la solicitud:', error); 
            }
        } 
    };


    const handleEnabledButtons = async (datos) => {
        
        try { 
            const res = await axios.post(`https://facturacionei3.com/ep_WebhooksEcart/TurnOn`, {
                OrganizationID: datos.OrganizationID,
                Store: datos.Ecommerce                
            });
            if (res.status === 200) {
                
                window.location.reload();


            } 
        } catch (error) {
            console.log('Error al realizar la solicitud:', error);
        } 
    }

    const handleDisabledButtons = async (datos, webHook) => {
        try { 
            const res = await axios.post(`https://facturacionei3.com/ep_WebhooksEcart/TurnOff`, {
                OrganizationID: datos.OrganizationID,
                Store: datos.Ecommerce,
                WebhookId : webHook.WebhookId     
            });
            if (res.status === 200) {  
                window.location.reload();
            } 
        } catch (error) {
            console.log('Error al realizar la solicitud:', error);
        } 
    }

    useEffect(() => {
        const orgID = localStorage.getItem('organizationID');
        setOrganizationID(orgID);
            
    }, []);

    useEffect(() => {
        specificationNotEnabled();
        getStores();
        
    }, [organizationID]);



    return (
        <div className="webhooks_container">
            <div className='webhooks_container_h2'>
                <h2>Webhooks de las tiendas</h2>
            </div>

            <div className='webhooks_tabla'>
                        <table className="webhooks_table">
                            <thead>
                                <tr >
                                    <th className="col-1">Logo</th>
                                    <th className="col-3">Nombre de la tienda</th>
                                    <th className="col-2">Configurar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map(datos => {

                                    let enabledIndex;
                                    let isEnabled;
                                    if(enabled){
                                        enabledIndex = enabled.findIndex(item => item.Store === datos.Ecommerce);
                                        isEnabled = enabledIndex !== -1;
                                    }
                                    else{
                                        isEnabled = null;
                                    }
                                        
                                            return (
                                                <tr key={datos._id} className="webhooks_table_title">
                                                    <td><img src={getImagePath(datos.Ecommerce)} alt="" /></td>
                                                    <td style={{fontWeight:'800'}}>{datos.Ecommerce}</td>
                                                    <td>
                                                        {
                                                            isEnabled ? (
                                                                <>
                                                                    <button className='deshabilitado' onClick={() => handleDisabledButtons(datos, enabled[enabledIndex])}>Deshabilitar</button> 
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button className='habilitado' onClick={() => handleEnabledButtons(datos)}>Habilitar</button>
                                                                </>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            );

                                })}
                            </tbody>

                        </table>
                    </div>
        </div>
    )
}


